.crud-table {
  .ant-table {
    border-top: 1px solid #f0f0f0;
    border-radius: 0px;
  }
  .ant-table-wrapper .ant-table-thead > tr > th {
    background-color: #fff;
  }
}

.crud-table-header {
  padding-bottom: 16px;
  padding-top: 16px;
  border-top: 1px solid #f0f0f0;
}

.crud-table-modal {
  max-width: 1180px;
  .ant-modal-body {
    padding-top: 14px;
  }
}
