@import 'styles/var.scss';
@import 'styles/layout.scss';
@import 'styles/flex.scss';
@import 'styles/font.scss';
@import 'styles/cursor.scss';
@import 'styles/responsive.scss';

* {
  margin: 0;
  padding: 0;
  font-size: inherit;
  color: inherit;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw;
  height: 100vh;
  max-width: 100vw;
  max-height: 100vh;
  overflow: hidden;
}

#root {
  height: 100%;
  width: 100%;
}

.full-div {
  width: 100%;
  height: 100%;
}