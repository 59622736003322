$position: top, right, bottom, left;

@each $item in $position {
  .m#{str-slice($item, 0, 1)}-auto {
    margin-#{$item}: auto;
  }
  .m#{str-slice($item, 0, 1)}-0 {
    margin-#{$item}: 0px;
  }
}

@for $i from 2 to 100 {
  @each $item in $position {
    .m#{str-slice($item, 0, 1)}-#{$i} {
      margin-#{$item}: #{$i}px;
    }
    .p#{str-slice($item, 0, 1)}-#{$i} {
      padding-#{$item}: #{$i}px;
    }
  }
  .height-#{$i} {
    height: #{$i}px;
  }
  .width-#{$i} {
    width: #{$i}px;
  }
}
