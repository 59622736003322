body {
  --needcode-white: #ffffff;

  --needcode-primary: rgba(0, 120, 212, 1);
  --needcode-primary-1: rgba(0, 120, 212, 0.1);

  --needcode-second: #939597;
  --needcode-second-1: rgba(147, 149, 151, 0.1);

  --needcode-radius: 12px;
}
